import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@Components/Button';
import { Hidden } from '@mui/material';
import WrappedInstallationMapper from './WrappedInstallationMapper';
import rightArrow from '../../assets/rightArrow.svg';
import HandleNav from './HandleNav/HandleNav';
import classes from './Installation.module.scss';
import { installationStore } from '@Store/installationStore';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import ButtonRefsContext, {
  useButtonRefs,
} from './hooks/NavigationButtons.context';
import InstallationSteps from './InstallationSteps/InstallationSteps';
import ProductHeader from './ProductHeader';
import { getUserSFDetails } from 'utils/getUserAccount';
import { RequestForTransfer } from '../dahboard/RotaryAccordion/RequestForTransfer';
import { spinnerStore } from '@Store/spinnerStore';
import regSuccess from '../../assets/regSuccess.svg';
function Installation() {
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');

  // const { id } = useParams();
  const email = getUserSFDetails().email;
  // const { data } = dashboardQuery.useProduct(email, ids, true);
  const { data, status, isLoading, isRefetching } =
    dashboardQuery.useMultiProducts(email, ids, true);
  const navigate = useNavigate();
  const handleClick = (event) => {
    localStorage.removeItem('CheckedValues');
    localStorage.removeItem('CheckedValue');
    navigate('/Dashboard');
  };
  const { nextButtonRef, prevButtonRef } = useButtonRefs();
  const { setEmptyState } = installationStore();
  const [open, setOpen] = React.useState(false);
  const { setSpinner, isActive } = spinnerStore();
  const handleOpen = () => setOpen(true);
  const user = getUserSFDetails();
  useEffect(() => {
    return () => setEmptyState();
  }, []);

  const steps = installationStore((state) => state.steps);
  const { currentStep } = steps || {};
  // const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, []);
  const statusId = localStorage.getItem('statusId');
  // const valueFalg = user.userRole === 'CSR' ? true : false;
  const valueFalg = user.userRole === 'CSR' && statusId === '1' ? true : false;
  const basicUserFlag = user.userRole === 'Basic User' ? true : false;
  return (
    <div className={classes.InstallationContainer}>
      <div className={classes.TopNavigationCont}>
        <div className={classes.TopNavigationSection}>
          <div>
            {!valueFalg ? (
              <h2>New Equipment Registration</h2>
            ) : (
              <h2>Equipment Information</h2>
            )}
          </div>
          <div className={classes.buttonStyles}>
            <Hidden mdDown>
              <div className={classes.CancelRegister} id="cancelRegister">
                {!valueFalg ? (
                  <Link to="/">
                    <Button
                      type="submit"
                      variant="outlined"
                      className={classes.cancelRegistration}
                    >
                      Cancel Registration
                    </Button>
                  </Link>
                ) : (
                  <Button
                    type="submit"
                    variant="outlined"
                    className={classes.cancelRegistration}
                    onClick={handleOpen}
                  >
                    Request for Transfer
                  </Button>
                )}
              </div>
            </Hidden>
            {
              <div className={classes.RegisterEquipt_Modal}>
                {open && (
                  <RequestForTransfer
                    open={open}
                    setOpen={setOpen}
                    eq={data[0]}
                  />
                )}
              </div>
            }
          </div>
        </div>
        <div className={classes.SubNavigationSection}>
          <Link
            to="/Dashboard"
            className={classes.ActiveLink}
            onClick={handleClick}
          >
            My Equipment
          </Link>
          <img src={rightArrow} alt="rightArrow" />
          {!valueFalg ? (
            <Link to="/">New Equipment Registration</Link>
          ) : (
            <span className={classes.navTextStyles}>Equipment Information</span>
          )}
          <Hidden mdUp>
            <div className={classes.CancelRegister} id="cancelRegister">
              {/* {!valueFalg ? ( */}
              <Link to="/Dashboard">
                <Button type="submit" variant="outlined">
                  Cancel
                </Button>
              </Link>
              {/* ) : ( */}
              {/* <Button
                  type="submit"
                  variant="outlined"
                  className={classes.cancelRegistration}
                  onClick={handleOpen}
                >
                  Request for Transfer
                </Button> */}
              {/* )} */}
            </div>
          </Hidden>
        </div>
      </div>
      <ButtonRefsContext.Provider value={{ nextButtonRef, prevButtonRef }}>
        <div className={classes.InstallationMiddleSection}>
          <div className={classes.InstallationCardCont}>
            {(!valueFalg && !basicUserFlag && currentStep <= 5) ||
            (basicUserFlag && !valueFalg && currentStep <= 4) ? (
              <div className={classes.headingSection}>
                <Hidden mdDown>
                  <div className={classes.productHeader}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className={classes.rotaryHeadSec}
                        >
                          <p className={classes.subHead}>Product Name</p>
                        </Grid>
                        <Grid item xs={6} md={2} sm={3}>
                          <p className={classes.subHead}>Serial Number</p>
                        </Grid>
                        <Grid item xs={6} md={2} sm={3}>
                          <p className={classes.subHead}>Product Code</p>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                          <p className={classes.subHead}>Distributor</p>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Hidden>
                <ProductHeader />
              </div>
            ) : (
              !valueFalg && (
                <div className={classes.productSuccessHead}>
                  <div>
                    <img src={regSuccess} alt="regSuccessIcon" />

                    <h2>
                      Congratulations! Your Equipment Has Been Successfully
                      Registered.
                    </h2>
                  </div>
                </div>
              )
            )}
            {/* <div className={classes.headingSection}>
              {data?.map((eq) => (
                <>
                  <ProductHeader equipment={eq} key={eq?.equipmentNumber} />
                </>
              ))}
              {/* <ProductHeader data={data} /> */}
            {/* </div>  */}
            <InstallationSteps />
            <div className={classes.installationMiddleCont}>
              <WrappedInstallationMapper />
            </div>
          </div>
        </div>

        <div className={classes.nextButton}>
          <HandleNav />
        </div>
      </ButtonRefsContext.Provider>
    </div>
  );
}

export default Installation;
